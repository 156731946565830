
@mixin cookie-bar-layout {
  position: relative;

  .cookie-bar__grid {
    @include grid-container;
  }

  .cookie-bar__bar-wrapper,
  .cookie-bar__categories {
    grid-column: span var(--grid-columns);
  }

  .cookie-bar__bar,
  .cookie-bar__details {
    bottom: 0;
    height: auto;
    left: 0;
    padding-bottom: var(--grid-padding);
    padding-top: var(--grid-padding);
    position: fixed;
    width: 100%;
  }

  .cookie-bar__bar {
    z-index: 9998;
  }

  .cookie-bar__details {
    z-index: 9999;

    .cookie-bar__grid {
      padding-top: var(--grid-padding);
    }
  }

  .cookie-bar__bar-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: var(--grid-padding);
    padding-right: var(--grid-padding);
    padding-top: calc(var(--grid-padding) * 1.5);

    &:first-child {
      padding-top: 0;
    }
  }

  .cookie-bar__text-container,
  .cookie-bar__buttons {
    transform: translate3d(0, 0, 0); // iOS 12 text visibility bug
  }

  .cookie-bar__buttons,
  .cookie-bar__details-buttons {
    white-space: nowrap;
  }

  .cookie-bar__buttons {
    @include clearfix;

    padding-top: var(--grid-padding);
  }

  .cookie-bar__text-container {
    padding-right: var(--grid-padding);
    width: 100%;

    .text__span,
    .link {
      margin-right: var(--grid-padding);
    }

    .link__span {
      white-space: nowrap;
    }
  }

  .cookie-bar__categories {
    @include clearfix;

    display: block;
  }

  .cookie-bar__category {
    float: left;
    padding-bottom: var(--grid-padding);
    padding-left: var(--grid-padding);
    padding-right: var(--grid-padding);
    width: 100%;
  }

  @include breakpoints(mobile-only, portrait-only) {
    .cookie-bar__bar-wrapper {
      flex-direction: column;
    }

    .cookie-bar__details {
      border-top: 0;
      height: 100%;
      left: 0;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      width: 100%;
    }

    .cookie-bar__buttons {
      white-space: initial;

      .button {
        width: 100%;

        + .button {
          margin-top: 1rem;
        }
      }
    }

    .link {
      clear: left;
      display: inline-block;
      float: left;
      padding: .5em 0;
    }
  }

  @include portrait {
    .cookie-bar__bar-wrapper,
    .cookie-bar__categories {
      grid-column: column 1 / span 6;
    }

    .cookie-bar__category {
      width: 50%;
    }
  }

  @include landscape {
    .cookie-bar__bar-wrapper,
    .cookie-bar__categories {
      grid-column: column 1 / span 12;
    }
  }

  @include notebook {
    .cookie-bar__bar-wrapper,
    .cookie-bar__categories {
      grid-column: column 1 / span 12;
    }
  }

  //@include desktop {
  //  .cookie-bar__bar-wrapper,
  //  .cookie-bar__categories {
  //    grid-column: column 2 / span 12;
  //  }
  //}
  //
  //@include desktop-large {
  //  .cookie-bar__bar-wrapper,
  //  .cookie-bar__categories {
  //    grid-column: column 2 / span 14;
  //  }
  //}
}

@mixin cookie-bar-styling {
  .cookie-bar__bar {
    background-color: $color-blue;
    color: $color-white;
  }

  .cookie-bar__details {
    background-color: $color-white;
    color: $color-black;
  }

  .cookie-bar__title {
    @include typography-h4;
  }

  .cookie-bar__text,
  .cookie-bar__text .link {
    @include typography-tag;

    text-transform: uppercase;
  }

  .cookie-bar__category-text {
    @include typography-body;
    @include disable-user-select;

    cursor: pointer;
    display: inline-block;
    max-width: 32em;
    padding-left: calc(var(--form-field-line-height) + var(--paragraph-line-height));
    padding-top: .5rem;
  }

  .cookie-bar__text {
    .text__span {
      display: block;
      margin-bottom: .5rem;
      margin-top: .5rem;
    }

    .link {
      color: inherit;

      .link__span {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        .link__span {
          text-decoration: none;
        }
      }
    }
  }

  .cookie-bar__text-container {
    display: flex;

    .cookie-bar__text {
      //align-self: flex-end;
      margin-bottom: 1rem;
      max-width: 50em;
    }
  }

  .button--accept {
    background-color: $color-blue;
    border-color: $color-white;
    color: $color-white;

    &:hover:not(.button--disabled):not(:disabled),
    &:focus:not(.button--disabled):not(:disabled),
    &.button--selected {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-blue;
    }
  }

  .button--accept-all {
    background-color: $color-white;
    border-color: $color-blue;
    color: $color-blue;

    &:hover:not(.button--disabled):not(:disabled),
    &:focus:not(.button--disabled):not(:disabled),
    &.button--selected {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;
    }
  }

  .button--save {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;

    &:hover:not(.button--disabled):not(:disabled),
    &:focus:not(.button--disabled):not(:disabled),
    &.button--selected {
      background-color: $color-white;
      border-color: $color-blue;
      color: $color-blue;
    }
  }
}

@mixin cookie-bar-display-logic {
  .cookie-bar__bar,
  .cookie-bar__details {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, calc(var(--grid-padding) * 1.5), 0);
    transition: opacity $easing-slower, transform $easing-slower;
  }

  .cookie-bar__bar:target,
  .cookie-bar__bar--visible,
  .cookie-bar__details:target,
  .cookie-bar__details--visible {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
}

.cookie-bar {
  @include cookie-bar-layout;
  @include cookie-bar-styling;
  @include cookie-bar-display-logic;
}
