
.article-photo {
  margin: 40px 0;
  position: relative;

  .article-photo__wrapper {
    position: relative;
  }
}

.article-photo--small {
  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: span var(--grid-columns);
  }

  @media (min-width: 960px) {
    .article-photo__wrapper {
      grid-column: column 5 / span 7;
    }
  }
}

.article-photo--full-width {
  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: span var(--grid-columns);
  }

  @include landscape {
    .article-photo__wrapper {
      grid-column: span var(--grid-columns);
    }
  }
}

.article-photo--full-bleed {
  .figure__caption {
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }
}
