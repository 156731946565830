
.homepage-scroller-indicator {
  bottom: 10px;
  color: $color-white;
  height: 30px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 30px;
  z-index: 4;

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: $color-white;
    }
  }

  .picture ~ & {
    position: absolute;
    z-index: 4;
  }
}
