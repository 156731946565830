
.figure {
  position: relative;

  .picture {
    height: 100%;
    width: 100%;
  }

  .figure__caption {
    @include typography-caption;

    color: $color-mediumdarkgrey;
    display: block;
    padding-top: 4px;
    text-align: right;
  }
}

.figure--default {
  //
}

.figure--background-image {
  .figure__picture-wrapper {
    padding-bottom: 56.25%; // 16:9
    position: relative;
  }
}
