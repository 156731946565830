
.header-image {
  margin-top: 150px;
  position: relative;

  .header-image__grid {
    @include grid-container;
  }

  .header-image__wrapper,
  .header-image__aside {
    grid-column: span var(--grid-columns);
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .header-image__category {
    @include typography-tag;

    color: $color-blue;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .header-image__title {
    @include typography-h1-title;

    color: $color-blue;
    margin-bottom: 1rem;
    margin-left: -.06em;
  }

  .header-image__title-span {
    text-transform: uppercase;
  }

  .header-image__title-count {
    @include typography-tag;

    display: inline-block;
    transform: translate3d(-1rem, -50%, 0);
    vertical-align: top;
  }

  .header-image__introduction {
    //
  }

  .header-image__picture {
    margin-top: 20px;

    // NOTE: Right side bleed uitgezet - klant vind het spuuglelijk.
    //.picture {
    //  margin-right: calc((var(--grid-padding) + var(--grid-margin)) * -1);
    //  width: calc(100% + var(--grid-padding) + var(--grid-margin));
    //}
  }

  .header-image__caption {
    .richtext--tag {
      p {
        line-height: 1.5em;
      }
    }
  }

  .header-image__linklist {
    margin-top: var(--grid-spacing);
  }

  @include landscape {
    margin-top: 0;
    min-height: 200px;

    .header-image__wrapper {
      grid-column: column 5 / span 8;
      order: 2;
    }

    .header-image__aside {
      display: flex;
      flex-direction: column;
      grid-column: column 1 / span 4;
      margin-top: 200px;
      order: 1;

      > :first-child {
        margin-top: auto;
      }
    }

    .header-image__picture {
      margin-top: 40px;
    }
  }

  &.header-image--no-image {
    .header-image__aside {
      @include landscape {
        grid-column: column 5/span 4;
        margin-top: 0;
        order: 3;
      }
    }
  }
}
