
.button-group {
  align-items: center;
  display: flex;
  width: 100%;

  .button-group__label {
    @include typography-tag;

    text-transform: uppercase;
  }

  .button-group__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .button-group__item {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  &.button-group--vertical {
    .button-group__list {
      display: block;
    }
  }
}
