// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  // Breakpoints
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  //--breakpoint-desktop: #{$desktop};
  //--breakpoint-desktop-large: #{$desktop-large};

  // Breakpoint reference (for javascript)
  --breakpoint: var(--breakpoint-mobile);

  // Document constraints
  --document-min-width: #{$website-min-width};
  --document-max-width: #{$website-max-width};

  // Helper variables
  --full-grid-column-width: calc((100% - (var(--grid-margin) * 2) - (var(--grid-padding) * (var(--grid-columns) + 1))) / var(--grid-columns));

  @include mobile {
    --grid-columns: 6; // TODO: 4 is easier
    --grid-debug-color: #f06;
    --grid-gutter: 8px;
    --grid-margin: 12px;
    --grid-max-width: 100%;
    --grid-padding: 16px;
    --grid-spacing: 16px;
  }

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
    --grid-columns: 6;
    --grid-gutter: 8px;
    --grid-margin: 24px;
    --grid-debug-color: #c3f;
    --grid-max-width: 100%;
    --grid-padding: 16px;
    --grid-spacing: 16px;
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landscape);
    --grid-columns: 12;
    --grid-gutter: 10px;
    --grid-margin: 30px;
    --grid-debug-color: #0cf;
    --grid-max-width: 1440px;
    --grid-padding: 20px;
    --grid-spacing: 20px;
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
    --grid-columns: 12;
    --grid-gutter: 10px;
    --grid-margin: 30px;
    --grid-debug-color: #3fc;
    --grid-max-width: 1440px;
    --grid-padding: 20px;
    --grid-spacing: 20px;
  }

  //@include desktop {
  //  --breakpoint: var(--breakpoint-desktop);
  //  --grid-columns: 14;
  //  --grid-debug-color: #f9c;
  //}

  //@include desktop-large {
  //  --breakpoint: var(--breakpoint-desktop-large);
  //  --grid-columns: 16;
  //  --grid-debug-color: #f06;
  //}

  // overlay settings
  //--repeating-width: calc(100% / var(--grid-columns));
  //--column-width: calc((100% / var(--grid-columns)) - var(--grid-padding));
  //--background-width: calc(100% + var(--grid-padding));
  //--background-columns: repeating-linear-gradient(to right, var(--grid-debug-color), var(--grid-debug-color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width));
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))', $with-spacing: false) {
  display: grid;
  grid-gap: if($with-spacing, var(--grid-spacing), 0) var(--grid-padding);
  grid-template-columns: unquote($columns);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--grid-max-width) + (var(--grid-padding) * 2) + (var(--grid-margin) * 2));
  padding-left: calc(var(--grid-padding) + var(--grid-margin));
  padding-right: calc(var(--grid-padding) + var(--grid-margin));
}
