.plus-corners {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  .plus-corners__tl,
  .plus-corners__tr,
  .plus-corners__br,
  .plus-corners__bl {
    display: block;
    height: 20px;
    overflow: hidden;
    width: 20px;
  }

  .plus-corners__tl {
    left: -10px;
    top: -10px;
  }

  .plus-corners__tr {
    right: -10px;
    top: -10px;
  }

  .plus-corners__br {
    bottom: -10px;
    right: -10px;
  }

  .plus-corners__bl {
    bottom: -10px;
    left: -10px;
  }

  li {
    height: 20px;
    position: absolute;
    width: 20px;

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M10.7999 9.2V2H9.19994V9.2H2V10.8H9.19994V18H10.7999V10.8H18V9.2H10.7999Z' fill='black'/%3E%3C/svg%3E%0A");
      content: '';
      display: block;
      height: 20px;
      transition: .5s;
      width: 20px;
    }
  }

  &.is-hover {
    li::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M10.7999 9.2V2H9.19994V9.2H2V10.8H9.19994V18H10.7999V10.8H18V9.2H10.7999Z' fill='%23006bff'/%3E%3C/svg%3E%0A");
      transform: rotate(45deg);
    }
  }
}
