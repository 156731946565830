
.menu {
  color: currentColor;

  .menu__underlayer {
    cursor: pointer;
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $z-index-menu-overlay;

    &.is-active {
      display: block;
    }
  }

  .menu__overlay {
    background-color: $color-blue;
    color: $color-white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: scale(1);
    transition: transform $easing-outofview, opacity $easing-outofview;
    width: 100vw;
    z-index: $z-index-menu-overlay;

    .menu__buttons {
      padding: 0 calc(var(--grid-padding) + var(--grid-margin)) 0;
    }

    ~ .menu__buttons .button--menu,
    .menu__buttons .button--menu {
      display: inline-block;
    }

    ~ .menu__buttons .button--close,
    .menu__buttons .button--close {
      display: none;
    }

    &:target,
    &.target {
      opacity: 1;
      pointer-events: initial;
      transform: scale(1);
      transition: transform $easing-intoview, opacity $easing-intoview;

      ~ .menu__buttons .button--menu,
      .menu__buttons .button--menu {
        display: none;
      }

      ~ .menu__buttons .button--close,
      .menu__buttons .button--close {
        display: inline-block;
      }
    }
  }

  .menu__items {
    flex: 1;
    padding: 40px calc(var(--grid-padding) + var(--grid-margin)) calc(var(--grid-spacing) * 2);
  }

  .menu__item {
    margin-bottom: var(--grid-spacing);
  }

  .menu__item-anchor {
    @include typography-h1;

    color: $color-white;
    display: block;
    text-transform: uppercase;
    transition: color $easing-hover;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .menu__buttons {
    float: right;
    height: 40px;
    margin-top: var(--grid-spacing);
    text-align: right;

    .button {
      display: inline-block;
      height: 40px;
      line-height: 24px;
      margin-bottom: -8px;
      margin-top: -8px;
      min-width: auto;
      overflow: hidden;
      padding: 8px;
      vertical-align: top;
      width: 40px;

      .button__span {
        display: none;
      }

      .button__icon {
        margin: 0;
      }
    }
  }

  .menu__language-items {
    left: calc(var(--grid-padding) + var(--grid-margin));
    position: absolute;
    top: 5px;

    @include typography-h4;

    @include landscape {
      top: 10px;
    }
  }

  .menu__language-item-anchor,
  .menu__language-item-span {
    color: $color-white;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
  }

  .menu__language-item-anchor {
    transition: color $easing-hover;

    &:hover {
      text-decoration: underline;
    }
  }

  @include landscape {
    .menu__overlay {
      left: 50%;
      position: fixed;
      top: 0;
      width: 50%;

      .menu__buttons {
        max-width: calc((var(--document-max-width) / 2) + var(--grid-padding) + var(--grid-margin));
      }
    }
  }
}
