
.article-cookies {
  margin: 40px 0;
  position: relative;

  .article-cookies__grid {
    @include grid-container;
  }

  .article-cookies__wrapper {
    grid-column: span var(--grid-columns);
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .article-cookies__title {
    @include typography-h2;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    text-transform: uppercase;
  }

  .article-cookies__text {
    @include typography-body;

    margin-block-end: 2rem;
    margin-block-start: .5rem;
  }

  @include landscape {
    .article-cookies__wrapper {
      grid-column: column 5 / span 7;
      order: 2;
    }
  }
}
