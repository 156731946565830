
.homepage-scroller-images-slider {
  height: 100%;
  position: relative;
  width: 100%;

  .homepage-scroller-images-slider__grid {
    @include grid-container;

    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-between;
    width: 100%;
  }

  .homepage-scroller-images-slider__links {
    bottom: 70px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 4;
  }

  .homepage-scroller-images-slider__link {
    display: none;

    .button {
      color: $color-white;
    }

    &.is-active {
      display: block;
    }
  }

  .homepage-scroller-images-slider__images {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .homepage-scroller-images-slider__image {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 1s ease;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    .picture {
      background-color: transparent;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .picture__image {
      transform: scale(1.25);
      transition: transform 5s ease-in-out, opacity .35s;
    }

    &.is-previous {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }

    &.is-current {
      opacity: 1;
      visibility: visible;
      z-index: 3;
    }

    &.is-animating {
      &[data-transition='is-move-right'] {
        .picture__image {
          transform: scale(1.25) translateX(5%);
        }
      }

      &[data-transition='is-move-left'] {
        .picture__image {
          transform: scale(1.25) translateX(-5%);
        }
      }

      &[data-transition='is-zoom-in'] {
        .picture__image {
          transform: scale(1.35);
        }
      }

      &[data-transition='is-zoom-out'] {
        .picture__image {
          transform: scale(1.15);
        }
      }
    }
  }

  .homepage-scroller-images-slider__titles {
    align-items: center;
    color: $color-white;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }

  .homepage-scroller-images-slider__titles-top,
  .homepage-scroller-images-slider__titles-bottom {
    position: relative;
    text-align: center;
  }

  .homepage-scroller-images-slider__titles-wrapper {
    @include typography-h2;

    height: 100%;
    line-height: 1 !important;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;

    //&::before {
    //  content: '\feff';
    //}
  }

  .homepage-scroller-images-slider__title {
    font-size: clamp(20px, 5vw, 40px);
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    @include landscape {
      font-size: clamp(20px, 5vw, 40px);
    }

    &.is-current {
      .homepage-scroller-images-slider__title-inner {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.is-previous {
      .homepage-scroller-images-slider__title-inner {
        opacity: 1;
        transform: translateY(-100%);
      }
    }
  }

  .homepage-scroller-images-slider__title-inner {
    display: inline-block;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 1s ease-in-out;
  }

  .homepage-scroller-images-slider__titles-top {
    order: 2;

    @include landscape {
      order: 1;
    }

    .homepage-scroller-images-slider__title {
      right: 0;
    }
  }

  .homepage-scroller-images-slider__titles-bottom {
    order: 1;

    @include landscape {
      order: 2;
    }

    .homepage-scroller-images-slider__title {
      left: 0;
    }
  }

  &[data-initialized='false'] {
    .homepage-scroller-images-slider__title {
      &.is-previous {
        .homepage-scroller-images-slider__title-inner {
          transition: 0s;
        }
      }
    }
  }

  @include landscape {
    .homepage-scroller-images-slider__grid-container {
      height: 70vh;
    }

    .homepage-scroller-images-slider__links {
      bottom: 40px;
      left: auto;
      right: 40px;
    }

    .homepage-scroller-images-slider__titles-top {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }

    .homepage-scroller-images-slider__titles-bottom {
      display: flex;
      text-align: left;
    }
  }
}
