// Document Typography
// NOTE: The edge stuff is because it crashes when accessing css variables from inside the html element.

@import '../../../assets/stylesheets/utilities/mixins/fix-antialiasing';

:root {
  @include fix-antialiasing; // Prevent smudgy bold fonts on OSX

  font-size: 16px;
  font-size-adjust: initial;
  -webkit-font-smoothing: antialiased; // Prevent smudgy bold fonts on OSX
  line-height: 1.5em;
  text-rendering: optimizeLegibility;

  em,
  i {
    //font-style: oblique;
    font-style: normal; // Prevent faux italic since the licensed font family parts don't include italics
  }

  strong,
  b {
    font-weight: bold;
  }
}

// Typography mixin
// How to use:
// 1. duplicate typography/_*.scss
// 2. change values therein
// 3. when applying, use @typography(name) (inside breakpoint is fine)

@mixin typography($name: '', $margin-top: false, $margin-bottom: false, $adaptive-margins: true, $vertical-rhythm-padding: true) {
  color: var(--#{$name}-color);
  font-family: var(--#{$name}-font-family);
  font-feature-settings: var(--#{$name}-font-feature-settings);
  font-size: var(--#{$name}-font-size);
  font-style: var(--#{$name}-font-style);
  font-variant: var(--#{$name}-font-variant);
  font-weight: var(--#{$name}-font-weight);
  letter-spacing: var(--#{$name}-letter-spacing);
  line-height: var(--#{$name}-line-height);
  text-decoration: var(--#{$name}-text-decoration);
  text-decoration-thickness: var(--#{$name}-text-decoration-thickness);
  text-transform: var(--#{$name}-text-transform);
  text-underline-offset: var(--#{$name}-text-underline-offset);
  word-spacing: var(--#{$name}-word-spacing);

  @if $margin-top {
    @if $adaptive-margins {
      &:not(:first-child) {
        margin-top: var(--#{$name}-margin-top);
      }
    } @else {
      margin-top: var(--#{$name}-margin-top);
    }
  }

  @if $margin-bottom {
    @if $adaptive-margins {
      &:not(:last-child) {
        margin-bottom: var(--#{$name}-margin-bottom);
      }
    } @else {
      margin-bottom: var(--#{$name}-margin-bottom);
    }
  }

  @if $vertical-rhythm-padding {
    padding-bottom: var(--#{$name}-padding-bottom);
    padding-top: var(--#{$name}-padding-top);
  }
}

// Names and properties are derived from the Figma design file
@mixin typography-h1 {
  @include font-roboto;

  font-size: 32px;
  font-weight: get-roboto-weight(bold);
  letter-spacing: .12em;
  line-height: 36px;

  @include landscape {
    font-size: 48px;
    line-height: 54px;
  }
}

@mixin typography-h1-title {
  @include font-roboto;

  font-size: 36px;
  font-weight: get-roboto-weight(bold);
  letter-spacing: .06em;
  line-height: 40px;

  @include landscape {
    font-size: 48px;
    line-height: 54px;
  }
}

@mixin typography-h2 {
  @include font-roboto;

  font-size: 18px;
  font-weight: get-roboto-weight(bold);
  letter-spacing: .12em;
  line-height: 28px;

  @include landscape {
    font-size: 28px;
    line-height: 40px;
  }
}

@mixin typography-h3 {
  @include font-roboto;

  font-size: 14px;
  font-weight: get-roboto-weight(medium);
  letter-spacing: .04em;
  line-height: 19px;

  @include landscape {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin typography-h4 {
  @include font-roboto;

  font-size: 12px;
  font-weight: get-roboto-weight(medium);
  letter-spacing: .16em;
  line-height: 16px;

  @include landscape {
    font-size: 16px;
    letter-spacing: .1em;
    line-height: 20px;
  }
}

// This one wasn't in the design, but we can't turn H5 off in the WYSIWYG editor
@mixin typography-h5 {
  @include font-roboto;

  font-size: 10px;
  font-weight: get-roboto-weight(bold);
  letter-spacing: .16em;
  line-height: 14px;

  @include landscape {
    font-size: 14px;
    letter-spacing: .1em;
    line-height: 16px;
  }
}

// This one wasn't in the design, but we can't turn H6 off in the WYSIWYG editor
@mixin typography-h6 {
  @include font-roboto;

  font-size: 8px;
  font-weight: get-roboto-weight(bold);
  letter-spacing: .16em;
  line-height: 12px;

  @include landscape {
    font-size: 12px;
    letter-spacing: .1em;
    line-height: 14px;
  }
}

@mixin typography-intro {
  @include font-merriweather;

  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 28px;

  @include landscape {
    font-size: 22px;
    line-height: 34px;
  }
}

@mixin typography-body {
  @include font-merriweather;

  font-size: 14px;
  font-weight: normal;
  letter-spacing: -.3px;
  line-height: 24px;

  @include landscape {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
}

@mixin typography-tag {
  @include font-roboto;

  font-size: 10px;
  font-weight: normal;
  letter-spacing: .12em;
  line-height: 15px;

  @include landscape {
    font-size: 12px;
    line-height: 15px;
  }
}

@mixin typography-caption {
  @include font-merriweather;

  font-size: 12px;
  font-style: italic;
  font-weight: get-merriweather-weight(light);
  letter-spacing: 0;
  line-height: 18px;

  @include landscape {
    //
  }
}
