
.button {
  @include typography-h4;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0; // iOS
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 320px);
  min-width: 60px;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: color $easing-hover, background-color $easing-hover, border-color $easing-hover;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  .button__span {
    display: block;
    flex: 1 1 100%;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button__icon {
    display: block;
    flex: 0 0 24px;
    height: 24px;
    width: 24px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .button__icon--before {
    margin-left: -.65em;
  }

  .button__icon--after {
    margin-right: -.65em;
  }
}

.button:disabled,
.button--disabled {
  background-color: $color-white;
  border-color: $color-mediumgrey;
  color: $color-mediumgrey;
  cursor: default;
  //opacity: .25;
  pointer-events: none;
}

.button--secondary {
  background-color: $color-white;
  border-color: $color-blue;
  color: $color-blue;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }
}

.button--active {
  background-color: $color-blue;
  border-color: $color-blue;
  color: $color-white;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-white;
    border-color: $color-blue;
    color: $color-blue;
  }
}

.button--clean {
  background-color: transparent;
  border-color: transparent;
  color: currentColor;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: transparent;
    border-color: transparent;
    color: currentColor;
  }
}

.button--icon-only {
  display: flex;
  justify-content: center;
  min-width: 0;
  width: 40px;

  .button__icon--before {
    margin-left: auto;
  }
}

.button--small {
  @include typography-tag;

  height: 30px;
  min-width: 30px;
  padding: 4px 6px;

  .button__icon {
    flex: 0 0 13px;
    height: 15px;
    margin-left: 0;
    margin-right: 5px;
    width: 15px;
  }
}
