
.search-results {
  min-height: var(--80vh, 80vh);

  .search-results__grid {
    @include grid-container;
  }

  .search-results__input {
    grid-column: span 6;
    margin-top: 150px;
  }

  .search-results__pagination {
    grid-column: span 6;
  }

  @include landscape {
    .search-results__input {
      grid-column: span 10/12;
      margin-bottom: 30px;
      margin-top: 200px;
    }

    .search-results__pagination {
      grid-column: span 10/12;
    }
  }
}
