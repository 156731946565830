
.iframe-embed {
  position: relative;

  .iframe-embed__iframe {
    border-width: 0;
    overflow: auto;
    width: 100%;
  }
}
