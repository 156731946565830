
.share-button {
  @include typography-h4;

  background-color: $color-white;
  border: 1px solid $color-blue;
  color: $color-blue;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 38px;
  min-width: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: color $easing-hover, background-color $easing-hover;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 40px;

  &:hover,
  &:focus {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }

  .share-button__icon {
    display: block;
    height: 100%;
    padding: .5em;
    width: 100%;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
