
.homepage-scroller-horizontal-thumbnail-slider {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  .homepage-scroller-horizontal-thumbnail-slider__grid {
    @include grid-container;

    width: 100%;
  }

  .homepage-scroller-horizontal-thumbnail-slider__title-container {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    height: 60px;
    margin: auto;
    position: absolute;
    top: 0;
    transform: translateY(-210px);
    width: 100%;
    z-index: 2;

    @include portrait {
      height: 80px;
      transform: translateY(-280px);
    }
  }

  .homepage-scroller-horizontal-thumbnail-slider__title {
    @include typography-h2;

    align-items: center;
    display: flex;
    grid-column: span var(--grid-columns);
    justify-content: space-between;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;

    .navigation {
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  .homepage-scroller-horizontal-thumbnail-slider__swiper.swiper {
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 300px;
    position: relative;
    width: 80%;
  }

  .homepage-scroller-horizontal-thumbnail-slider__swiper-slide-last-inner {
    height: 100%;
    max-height: 300px;

    @include portrait {
      max-height: 400px;
    }
  }

  .swiper-navigation {
    display: inline-flex;
    font-size: 0;
    // top: 50%;
    // transform: translateY(-50%);
    margin-left: 10px;
    // position: absolute;
    right: 0;
    user-select: none;
    white-space: nowrap;

    .button {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: center;
      min-width: 0px;
      // padding: 9px;
      width: 40px;
      z-index: 10;

      .button__span {
        display: none;
      }

      .button__icon {
        margin: 0;
      }
    }

    .button--previous {
      margin-right: -1px;
    }
  }

  .thumbnail__content {
    position: absolute;
    width: 100%;
  }

  .homepage-scroller-horizontal-thumbnail-slider__swiper {
    height: 100%;
  }

  .homepage-scroller-horizontal-thumbnail-slider__button-container {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    height: 60px;
    margin: auto;
    position: absolute;
    top: 0;
    transform: translateY(240px);
    width: 100%;
    z-index: 2;

    @include portrait {
      height: 80px;
      transform: translateY(280px);
    }

    &.is-mobile {
      @include landscape {
        display: none;
      }
    }

    &.is-desktop {
      bottom: auto;
      display: none;
      height: 40px;
      position: relative;
      top: auto;
      transform: none;

      @include landscape {
        display: block;
      }
    }
  }

  .homepage-scroller-horizontal-thumbnail-slider__buttons {
    grid-column: span var(--grid-columns);
    position: relative;
    white-space: nowrap;
  }

  @include portrait {
    .swiper-slide {
      max-width: 400px;
    }
  }
}
