
.filter-bar {
  position: relative;

  .filter-bar__grid {
    @include grid-container;
  }

  .filter-bar__header,
  .filter-bar__main {
    grid-column: span var(--grid-columns);
  }

  .filter-bar__actives {
    .button-group__list {
      display: block;
    }
  }

  .filter-bar__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .filter-bar__main {
    display: none;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .filter-bar__category {
    margin-bottom: 30px;
    margin-right: 30px;
  }

  .filter-bar__category-title {
    @include typography-h4;

    color: $color-blue;
    text-transform: uppercase;
  }

  .filter-bar__category-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .filter-bar__category-item {
    //
  }

  .filter-bar__btn {
    margin-bottom: 10px;
    margin-right: 10px;

    &.button--clean {
      color: $color-blue;
      margin-right: 0;
      padding-right: 0;

      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }

  .filter-bar__toggle {
    .button__icon {
      transition: all .25s ease-in-out;
    }
  }

  &.is-open {
    .filter-bar__header {
      margin-bottom: 0px;
    }

    .filter-bar__toggle {
      .button__icon {
        transform: scaleY(-1);
      }
    }

    .filter-bar__actives {
      display: none;
    }

    .filter-bar__main {
      display: block;
    }
  }

  @include landscape {
    .filter-bar__actives {
      .button-group__list {
        display: flex;
      }
    }

    .filter-bar__main {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .filter-bar__category-list {
      max-width: 280px;
    }

    &.is-open {
      .filter-bar__main {
        display: flex;
      }
    }
  }
}
