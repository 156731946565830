
.article-photo-double {
  margin: 40px 0;
  position: relative;

  .article-photo-double__wrapper {
    position: relative;
  }
}

.article-photo-double--full-width {
  .article-photo-double__grid {
    @include grid-container;
  }

  .article-photo-double__wrapper {
    grid-column: span var(--grid-columns);
  }

  @include breakpoints(mobile-only, portrait-only) {
    .figure + .figure {
      margin-top: 20px;
    }
  }

  @include landscape {
    .article-photo-double__wrapper {
      @include clearfix;

      grid-column: span var(--grid-columns);
    }

    .figure {
      float: left;
      width: calc(50% - var(--grid-gutter));

      &:not(:last-child) {
        margin-right: var(--grid-gutter);
      }

      &:not(:first-child) {
        margin-left: var(--grid-gutter);
      }
    }
  }
}

.article-photo-double--full-bleed {
  @include breakpoints(mobile-only, portrait-only) {
    .figure + .figure {
      margin-top: 20px;
    }
  }

  @include landscape {
    .article-photo-double__wrapper {
      @include clearfix;
    }

    .figure {
      float: left;
      width: calc(50% - var(--grid-gutter));

      &:not(:last-child) {
        margin-right: var(--grid-gutter);
      }

      &:not(:first-child) {
        margin-left: var(--grid-gutter);
      }
    }
  }

  .figure__caption {
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }
}
