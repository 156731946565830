
@mixin plus-container-black {
  position: relative;

  &::before {
    border: 10px solid transparent;
    border-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' baseProfile='full' x='0' y='0' width='10' height='10' viewBox='0 0 10 10' preserveAspectRatio='xMidYMid meet'%3E%3Cg fill='%23000'%3E%3Crect x='4' y='0' width='2' height='10'/%3E%3Crect x='0' y='4' width='10' height='2'/%3E%3C/g%3E%3C/svg%3E%0A");
    content: '';
    height: calc(100% + 10px);
    left: -5px;
    position: absolute;
    top: -5px;
    transition: .25s;
    width: calc(100% + 10px);
    z-index: 0;
  }
}

@mixin plus-container-blue {
  position: relative;

  &::before {
    border: 10px solid transparent;
    border-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' baseProfile='full' x='0' y='0' width='10' height='10' viewBox='0 0 10 10' preserveAspectRatio='xMidYMid meet'%3E%3Cg fill='%23006bff'%3E%3Crect x='4' y='0' width='2' height='10'/%3E%3Crect x='0' y='4' width='10' height='2'/%3E%3C/g%3E%3C/svg%3E%0A");
    content: '';
    height: calc(100% + 10px);
    left: -5px;
    position: absolute;
    top: -5px;
    transition: .25s;
    width: calc(100% + 10px);
    z-index: 0;
  }
}

@mixin plus-container-blue-inset {
  position: relative;

  &::before {
    border: 10px solid transparent;
    border-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' baseProfile='full' x='0' y='0' width='10' height='10' viewBox='0 0 10 10' preserveAspectRatio='xMidYMid meet'%3E%3Cg fill='%23006bff'%3E%3Crect x='4' y='0' width='2' height='10'/%3E%3Crect x='0' y='4' width='10' height='2'/%3E%3C/g%3E%3C/svg%3E%0A");
    content: '';
    height: calc(100% + 10px);
    left: 10px;
    position: absolute;
    top: -5px;
    transition: .25s;
    width: calc(100% - 20px);
    z-index: 0;
  }
}
