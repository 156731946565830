
.picture {
  background-color: transparent;
  color: $color-black;
  display: block;
  position: relative;
  user-select: none;

  .picture__image {
    height: 100%;
    opacity: 0;
    transition: opacity .35s;
    width: 100%;
  }
}

.picture--loaded {
  .picture__image {
    opacity: 1;
  }
}

.picture--background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  &::after {
    background: rgba($color-black, .15);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  //.picture__image {
  //  filter: brightness(.66) contrast(1.1);
  //}

  ~ * {
    color: $color-white;
    position: relative;
    z-index: 1;
  }
}
