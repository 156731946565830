
.pagination {
  align-items: center;
  display: inline-block;
  font-size: 0;
  position: relative;
  text-align: center;
  width: 100%;

  .number-button {
    margin: 0 .1rem;
  }

  .number-button--selected,
  .number-button--dots {
    pointer-events: none;
  }

  .number-button--selected {
    text-decoration: underline;
    text-underline-offset: 3px;

    .number-button__span {
      //font-size: 150%;
    }
  }

  .number-button--previous {
    margin-right: 1rem;
  }

  .number-button--next {
    margin-left: 1rem;
  }
}
