
.logo {
  color: $color-white;
  display: inline-block;
  position: relative;
  transition: color ease-out .2s;
  vertical-align: bottom;

  .logo__svg {
    display: inline-block;
    height: auto;
    vertical-align: top;
    width: 100%;
  }

  .logo__g {
    fill: currentColor;

    polygon {
      transition: fill $easing-hover;

      &:nth-child(1) {
        transition-delay: .06s;
      }

      &:nth-child(2) {
        transition-delay: .12s;
      }

      &:nth-child(3) {
        transition-delay: .18s;
      }

      &:nth-child(4) {
        transition-delay: .25s;
      }
    }
  }

  // .logo__g2 {
  //   fill: $color-blue;
  // }

  &:hover {
    .logo__g2 {
      polygon {
        fill: $color-blue;
      }
    }
  }
}

.logo--default {
  color: $color-black;

  .logo__g {
    fill: $color-black;
  }

  .logo__g2 {
    polygon {
      fill: $color-black;
    }
  }

  &:hover {
    .logo__mini__plus path {
      fill: $color-blue;
    }
  }
}

.logo--black-blue {
  .logo__g {
    fill: $color-black;
  }

  .logo__g2 {
    polygon {
      fill: $color-blue;
    }
  }

  &:hover {
    .logo__g2 {
      polygon {
        fill: $color-black;
      }
    }
  }
}

.logo__big,
.logo__mini {
  left: 0;
  position: absolute;
  top: 0;
  // transition: .15s;
}

.logo__mini {
  color: currentColor;
  opacity: 0;
  visibility: hidden;

  .logo__mini__plus {
    path:nth-child(1) {
      transition-delay: .06s;
    }

    path:nth-child(2) {
      transition-delay: .12s;
    }
  }

  .logo__mini__plus path,
  .logo__mini__text path {
    fill: currentColor;
    transition: fill $easing-hover;
  }
}

.logo--hidden {
  opacity: 0;
  visibility: hidden;
}

.logo--mini {
  .logo__big {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .logo__mini {
    opacity: 1;
    visibility: visible;
  }
}
