
.richtext {
  position: relative;
}

.richtext--regular {
  h2 {
    @include typography-h2;

    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  h3 {
    @include typography-h3;

    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  h4 {
    @include typography-h4;

    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  h5 {
    @include typography-h5;

    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  h6 {
    @include typography-h6;

    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  p,
  ul,
  ol {
    @include typography-body;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  p {
    @include enable-strong;
    @include enable-emphasized;

    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 2rem;
    }
  }

  li {
    @include typography-body;
    @include adaptive-margin(calc(var(--paragraph-margin-top) * .5), calc(var(--paragraph-margin-bottom) * .5));
    @include enable-strong;
    @include enable-emphasized;
  }

  p,
  li {
    a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }

  ul {
    list-style: initial;
    list-style-type: square;
    padding-left: 1.4em;

    li::marker {
      color: $color-blue;
      display: inline-block;
      font-weight: bold;
      vertical-align: bottom;
      width: 1em;
    }
  }

  ol {
    padding-left: 0;

    li::before {
      color: $color-blue;
      display: inline-block;
      font-weight: bold;
      vertical-align: bottom;
      width: 1em;
    }
  }
}

.richtext--simple {
  p {
    @include typography-body;
    @include enable-strong;
    @include enable-emphasized;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }
}

.richtext--introduction {
  h2 {
    @include typography-h2;

    text-transform: uppercase;
  }

  h3 {
    @include typography-h3;

    text-transform: uppercase;
  }

  h4 {
    @include typography-h4;

    text-transform: uppercase;
  }

  h5 {
    @include typography-h5;

    text-transform: uppercase;
  }

  h6 {
    @include typography-h6;

    text-transform: uppercase;
  }

  p {
    @include typography-intro;
    @include enable-strong;
    @include enable-emphasized;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.richtext--caption {
  margin-bottom: 4px 0;

  p {
    @include typography-caption;
    @include enable-strong;
    @include enable-emphasized;

    color: $color-blue;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }
}

.richtext--tag {
  margin-bottom: 4px 0;

  p {
    @include typography-tag;
    @include enable-strong;
    @include enable-emphasized;

    color: $color-darkgrey;
    text-transform: uppercase;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }
}
