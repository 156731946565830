
.homepage-scroller-testimonial {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  .homepage-scroller-testimonial__link {
    bottom: 30px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 20;

    .button {
      color: $color-white;
    }
  }

  .homepage-scroller-testimonial__grid {
    @include grid-container;

    display: block;
  }

  .homepage-scroller-testimonial__quote {
    color: $color-white;
    left: 0;
    margin: auto;
    max-width: 320px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 60px;
    z-index: 1;
  }

  .homepage-scroller-testimonial__quote-text {
    @include typography-body;
  }

  .homepage-scroller-testimonial__quote-meta {
    margin-bottom: 15px;
    text-transform: uppercase;

    @include typography-tag;
  }

  .homepage-scroller-testimonial__image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    .picture {
      background-color: transparent;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .homepage-scroller-testimonial__inner {
    position: relative;
    z-index: 1;
  }

  .homepage-scroller-testimonial__text-container {
    @include typography-h2;

    color: $color-white;
    height: 50%;
    text-align: center;
    text-transform: uppercase;

    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 70px;
    }

    &:nth-child(2) {
      padding-top: 70px;
    }
  }

  @include landscape {
    .homepage-scroller-testimonial__link {
      left: auto;
      right: 30px;
      width: auto;
    }

    .homepage-scroller-testimonial__inner {
      column-count: 2;
      column-gap: 220px;
      display: block;
    }

    .homepage-scroller-testimonial__text-container {
      height: auto;

      &:nth-child(1) {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}
