
.properties {
  margin: 40px 0;
  position: relative;
  z-index: 1;

  @include landscape {
    &.has-no-intro {
      height: 0;

      & + .article-content {
        margin-top: -40px;
      }
    }
  }

  .properties__grid {
    @include grid-container;
  }

  .properties__wrapper,
  .properties__aside {
    grid-column: span var(--grid-columns);
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .properties__list {
    margin-bottom: 2rem;
  }

  .properties__list-title {
    @include typography-h4;

    color: $color-blue;
    margin-bottom: 10px;
    margin-top: 3px;
    padding-left: 24px;
    text-transform: uppercase;
  }

  .properties__list-item-title {
    @include clearfix;

    padding: 5px 0;
  }

  .properties__list-item-title-icon {
    display: block;
    float: left;
    height: 24px;
    width: 24px;

    svg {
      margin: 0 0 0 -7px;
    }
  }

  .properties__list-item-title-span {
    @include typography-tag;

    display: block;
    padding: 5px 0 4px;
    text-transform: uppercase;
  }

  .button {
    margin-bottom: 7px;
  }

  @include landscape {
    .properties__wrapper {
      grid-column: column 5 / span 7;
    }

    .properties__aside {
      grid-column: column 1 / span 4;
      height: 0;
      overflow-y: visible;
    }
  }
}
