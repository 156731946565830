
.footer {
  background-color: $color-white;
  color: $color-black;
  padding-bottom: 15px;
  padding-top: 60px;

  .footer__grid {
    @include grid-container;
  }

  .footer__wrapper {
    grid-column: span var(--grid-columns);
  }

  .footer__column {
    grid-column: span calc(var(--grid-columns) / 2);
    margin-bottom: 40px;
  }

  .footer__title {
    @include typography-h4;

    margin-bottom: var(--grid-spacing);
    text-transform: uppercase;
  }

  .logo {
    max-width: 115px;
    width: 100%;

    @media (min-width: 480px) {
      max-width: 140px;
    }

    @media (min-width: 640px) {
      margin-bottom: 30px;
      max-width: 200px;
    }

    @include landscape {
      max-width: 160px;
    }

    @media (min-width: 1100px) {
      max-width: 210px;
    }
  }

  .logo__big {
    position: relative;
  }

  .footer__text {
    @include typography-body;

    color: $color-black;

    a {
      color: $color-black;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }

  .footer__name {
    @include typography-h4;

    span.break {
      display: block;
    }
  }

  .footer__bottom-link {
    @include typography-tag;

    color: $color-darkgrey;
    margin-right: 1em;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $color-blue;
      text-decoration: none;
    }
  }

  .footer__main {
    display: flex;
    flex-wrap: wrap;
    padding-left: calc(var(--grid-padding) + var(--grid-margin));
    padding-right: calc(var(--grid-padding) + var(--grid-margin));
    position: relative;
    width: 100%;

    .footer__column {
      width: 50%;
    }
  }

  .button {
    height: 26px;
    margin: 5px 5px 0 0;
    min-height: auto;
    min-width: auto;
    padding: 0;
    width: 26px;

    .button__icon {
      margin: 0;
    }

    .button__span {
      display: none;
    }
  }

  @include landscape {
    padding-top: 80px;
    text-align: left;

    .footer__wrapper {
      grid-column: span 12;
    }

    .footer__column {
      grid-column: span 3;
      margin-bottom: 16px;
    }

    .logoContainer {
      flex: 1;
    }

    .footer__name {
      span.break {
        display: inline-block;
        width: 5px;
      }
    }

    .footer__main {
      padding-left: calc(var(--grid-padding) + var(--grid-margin) + 25%);

      @media (min-width: 1100px) {
        margin-bottom: 20px;
      }

      .logoContainer {
        left: calc(var(--grid-padding) + var(--grid-margin));
        position: absolute;
        width: 25%;
      }

      .logoName {
        margin-bottom: 30px;
        width: 100%;
      }

      .meta {
        width: 33.33%;
      }
    }

    .footer__title {
      font-size: 13px;
    }
  }
}
