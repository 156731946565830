
.overview-grid.overview-grid--infinite-scroll .overview-grid__grid {
  padding-bottom: 0;
  padding-top: 0;
}

.overview-grid {
  position: relative;

  .overview-grid__grid {
    @include grid-container;

    display: block;
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .overview-grid__row {
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
    position: relative;
  }

  .overview-grid__item {
    padding: 10px;
    position: relative;
    width: 100%;

    &:hover {
      z-index: 10;
    }
  }

  @include breakpoints(mobile-only, portrait-only) {
    .overview-grid__item {
      .picture {
        aspect-ratio: 2.08/1;
      }
    }

    .overview-grid__row {
      &:nth-child(3n+2) {
        display: flex;

        .overview-grid__item {
          .picture {
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }

  @include landscape {
    .overview-grid__row {
      display: flex;

      &:nth-child(3n+2) {
        .overview-grid__item:nth-child(1) {
          margin-left: 25%;
          width: 25%;

          .picture {
            aspect-ratio: 1/1;
          }
        }
      }

      &:nth-child(3n+3) {
        .overview-grid__item:nth-child(2) {
          margin-right: 25%;
          width: 25%;

          .picture {
            aspect-ratio: 1/1;
          }
        }
      }
    }

    .overview-grid__item {
      width: 50%;

      .picture {
        aspect-ratio: 2.08/1;
      }
    }
  }
}
