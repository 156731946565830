
.thumbnail {
  @include clearfix;

  color: $color-black;
  display: block;

  .swiper-slide & {
    user-select: none;
  }

  .picture {
    aspect-ratio: 1/1;

    &::after {
      background-color: $color-blue;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: .25s ease-in-out;
      width: 100%;
    }
  }

  .thumbnail__image {
    position: relative;
  }

  .thumbnail__tags {
    color: $color-white;
    left: 0;
    opacity: 0;
    padding: 16px;
    position: absolute;
    top: 0;
    transition: .25s ease-in-out;
    width: 100%;

    @include typography-tag;

    li {
      margin-bottom: 16px;
      text-transform: uppercase;
    }
  }

  .thumbnail__content {
    display: block;
    padding-bottom: calc(var(--grid-spacing) * 3);
    padding-top: 8px;
  }

  .thumbnail__subtitle,
  .thumbnail__title {
    display: block;
    transition: .25s ease-in-out;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .thumbnail__subtitle {
    @include typography-tag;

    color: $color-darkgrey;
    text-transform: uppercase;
  }

  .thumbnail__title {
    @include typography-h3;
  }

  &:hover {
    .thumbnail__tags {
      opacity: 1;
    }

    .thumbnail__title {
      text-decoration: underline;
    }

    .picture::after {
      opacity: .666;
    }

    .thumbnail__subtitle,
    .thumbnail__title {
      color: $color-blue;
    }
  }
}
