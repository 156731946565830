
.button-bar {
  position: relative;

  .button-bar__grid {
    @include grid-container;
  }

  .button-bar__buttons {
    grid-column: span var(--grid-columns);
  }
}
