
.homepage-scroller-image-thumbnails {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  .homepage-scroller-image-thumbnails__link {
    bottom: 30px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;

    .button {
      color: $color-white;
    }
  }

  .homepage-scroller-image-thumbnails__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-evenly;
    padding: 30px;
    position: relative;
    text-align: center;

    .picture {
      background-color: transparent;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .picture__image {
      display: block;
      height: 100%;
      object-fit: cover;
      position: absolute;
      transition: 1s ease-in-out;
      width: 100%;
      z-index: 0;
    }

    .picture--background {
      &::after {
        background-color: $color-blue;
        opacity: 0;
        transition: .25s ease-in-out;
      }
    }

    &:hover {
      .picture {
        .picture__image {
          transform: scale(1.1);
        }
      }

      .picture--background::after {
        opacity: .75;
      }
    }
  }

  .homepage-scroller-image-thumbnails__item-title {
    color: $color-white;
    text-transform: uppercase;
    z-index: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  .homepage-scroller-image-thumbnails__item-meta {
    @include typography-tag;

    color: $color-white;
    text-transform: uppercase;
    z-index: 1;
  }

  .homepage-scroller-image-thumbnails__title {
    @include typography-h2;

    text-transform: uppercase;
  }

  @include landscape {
    flex-direction: row;
    height: 100%;

    .homepage-scroller-image-thumbnails__link {
      left: auto;
      right: 30px;
      width: auto;
    }

    .homepage-scroller-image-thumbnails__item {
      height: 100%;
      width: 50%;

      &:nth-child(1) {
        .homepage-scroller-image-thumbnails__item-title {
          padding-right: 60px;
        }
      }

      &:nth-child(2) {
        .homepage-scroller-image-thumbnails__item-title {
          padding-left: 60px;
        }
      }
    }

    .homepage-scroller-image-thumbnails__item-meta {
      position: absolute;
      top: 50px;
    }
  }
}
