$ENV: 'production';// This is the main stylesheet target
@charset 'UTF-8';

//
//
// Stuff from previous HNS project
//@import '../vendor/susy/language/susy';
//@import '../vendor/bourbon/bourbon';
//@import '../vendor/sanitize/sanitize';
//@import '../vendor/fabrique/normalize/normalize';
//@import '../vendor/fabrique/mixins/all';
//@import '../vendor/fabrique/animations/all';
//@import 'settings/all';
//@import 'styles/all';
//@import '../vendor/fabrique/components/all';
//@import '../vendor/fabrique/elements/all';
//@import '../vendor/idangerous/swiper';
//@import 'website/all';
//
//

// Project fonts - Early in the chain so the browser can start loading the webfonts immediately
@import 'fonts/_merriweather.scss'; @import 'fonts/_roboto.scss';

// Grid system
@import 'grid/breakpoints';
@import 'grid/mixins';
@import 'grid/variables';

// Common definitions, functions, mixins and subsite mixins
@import 'utilities/definitions/_beziers.scss'; @import 'utilities/definitions/_scales.scss';
@import 'utilities/functions/_decimal-ceil.scss'; @import 'utilities/functions/_decimal-floor.scss'; @import 'utilities/functions/_decimal-round.scss'; @import 'utilities/functions/_fluid-values.scss'; @import 'utilities/functions/_list-helpers.scss'; @import 'utilities/functions/_map-deep-get.scss'; @import 'utilities/functions/_map-deep-set.scss'; @import 'utilities/functions/_repeat.scss'; @import 'utilities/functions/_selector-helpers.scss'; @import 'utilities/functions/_string-helpers.scss'; @import 'utilities/functions/_strip-units.scss'; @import 'utilities/functions/_svg-uri.scss';
@import 'utilities/mixins/_adaptive-margin.scss'; @import 'utilities/mixins/_adaptive-padding.scss'; @import 'utilities/mixins/_background-image.scss'; @import 'utilities/mixins/_browserhack-chrome.scss'; @import 'utilities/mixins/_browserhack-firefox.scss'; @import 'utilities/mixins/_browserhack-ie11plus.scss'; @import 'utilities/mixins/_clearfix.scss'; @import 'utilities/mixins/_count-children.scss'; @import 'utilities/mixins/_disable-emphasized.scss'; @import 'utilities/mixins/_disable-old-tags.scss'; @import 'utilities/mixins/_disable-strong.scss'; @import 'utilities/mixins/_disable-user-select.scss'; @import 'utilities/mixins/_enable-emphasized.scss'; @import 'utilities/mixins/_enable-strong.scss'; @import 'utilities/mixins/_fix-antialiasing.scss'; @import 'utilities/mixins/_fix-buggy-vh-device.scss'; @import 'utilities/mixins/_fix-ios-input-radius.scss'; @import 'utilities/mixins/_fix-transition-flicker.scss'; @import 'utilities/mixins/_fluid-font-size.scss'; @import 'utilities/mixins/_fluid-value.scss'; @import 'utilities/mixins/_force-gpu-rendering.scss'; @import 'utilities/mixins/_hover.scss'; @import 'utilities/mixins/_invisible-anchors.scss'; @import 'utilities/mixins/_ios-overflow-scroll.scss'; @import 'utilities/mixins/_list-reset.scss'; @import 'utilities/mixins/_nullify-content-breaks.scss'; @import 'utilities/mixins/_override-tap-highlight-color.scss'; @import 'utilities/mixins/_placeholder.scss'; @import 'utilities/mixins/_plus-container.scss'; @import 'utilities/mixins/_prefixed.scss'; @import 'utilities/mixins/_remove-box-shadow.scss'; @import 'utilities/mixins/_remove-input-spinner.scss'; @import 'utilities/mixins/_selected-text.scss'; @import 'utilities/mixins/_set-link-color.scss'; @import 'utilities/mixins/_set-strong-tag-weight.scss'; @import 'utilities/mixins/_spaceless-inline-blocks.scss'; @import 'utilities/mixins/_text-ellipsis.scss'; @import 'utilities/mixins/_visually-hidden.scss';

// Normalization (before env this time because there is output in typography env file)
@import 'utilities/normalize/normalize';

// Project environment
@import 'definitions/variables';
@import 'definitions/keyframes';
@import 'definitions/mixins';

@import 'definitions/typography';
@import 'definitions/layouts';
@import 'definitions/styles';

// Project output

// Structures on top for faster rendering
@import '../../components/structures/cookie-bar/cookie-bar.scss'; @import '../../components/structures/document/document.scss'; @import '../../components/structures/footer/footer.scss'; @import '../../components/structures/header/header.scss'; @import '../../components/structures/skip-to-main/skip-to-main.scss';
@import '../../components/atoms/button/button.scss'; @import '../../components/atoms/figure/figure.scss'; @import '../../components/atoms/homepage-scroller-indicator/homepage-scroller-indicator.scss'; @import '../../components/atoms/iframe-embed/iframe-embed.scss'; @import '../../components/atoms/linklist/linklist.scss'; @import '../../components/atoms/logo/logo.scss'; @import '../../components/atoms/menu/menu.scss'; @import '../../components/atoms/number-button/number-button.scss'; @import '../../components/atoms/option-field/option-field.scss'; @import '../../components/atoms/picture/picture.scss'; @import '../../components/atoms/plus-corners/plus-corners.scss'; @import '../../components/atoms/richtext/richtext.scss'; @import '../../components/atoms/search-field/search-field.scss'; @import '../../components/atoms/share-button/share-button.scss'; @import '../../components/atoms/video-embed/video-embed.scss';
@import '../../components/molecules/button-group/button-group.scss'; @import '../../components/molecules/homepage-scroller-content/homepage-scroller-content.scss'; @import '../../components/molecules/homepage-scroller-horizontal-thumbnail-slider/homepage-scroller-horizontal-thumbnail-slider.scss'; @import '../../components/molecules/homepage-scroller-image-thumbnails/homepage-scroller-image-thumbnails.scss'; @import '../../components/molecules/homepage-scroller-images-slider/homepage-scroller-images-slider.scss'; @import '../../components/molecules/homepage-scroller-testimonial/homepage-scroller-testimonial.scss'; @import '../../components/molecules/pagination/pagination.scss'; @import '../../components/molecules/thumbnail/thumbnail.scss';
@import '../../components/organisms/article-content/article-content.scss'; @import '../../components/organisms/article-cookies/article-cookies.scss'; @import '../../components/organisms/article-footer/article-footer.scss'; @import '../../components/organisms/article-iframe/article-iframe.scss'; @import '../../components/organisms/article-photo-double/article-photo-double.scss'; @import '../../components/organisms/article-photo/article-photo.scss'; @import '../../components/organisms/article-video/article-video.scss'; @import '../../components/organisms/button-bar/button-bar.scss'; @import '../../components/organisms/carousel/carousel.scss'; @import '../../components/organisms/filter-bar/filter-bar.scss'; @import '../../components/organisms/header-image/header-image.scss'; @import '../../components/organisms/homepage-scroller/homepage-scroller.scss'; @import '../../components/organisms/homepage-tweener/homepage-tweener.scss'; @import '../../components/organisms/overview-grid/overview-grid.scss'; @import '../../components/organisms/people-grid/people-grid.scss'; @import '../../components/organisms/project-header/project-header.scss'; @import '../../components/organisms/properties/properties.scss'; @import '../../components/organisms/related-people-grid/related-people-grid.scss'; @import '../../components/organisms/search-results/search-results.scss'; @import '../../components/organisms/simple-grid/simple-grid.scss';

// Plugins


@import 'swiper/swiper';
@import 'swiper/modules/effect-fade/effect-fade';

html.debug-layout * {
  outline: 1px dashed var(--grid-debug-color);
}
