
.option-field {
  padding-top: 10px;
  position: relative;

  .option-field__label {
    @include typography-caption;

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .option-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .option-field__item {
    @include typography-body;

    display: block;
  }

  .option-field__input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus {
      + .option-field__input-label {
        &::before {
          border: 1px solid $color-black;
          bottom: -5px;
          content: '';
          left: -5px;
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }
    }

    &:disabled {
      + .option-field__input-label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + .option-field__input-label {
        .option-field__icon-foreground {
          opacity: 1;
        }
      }
    }
  }

  .option-field__input-label {
    @include typography-h3;

    color: $color-blue;
    cursor: pointer;
    display: block;
    font-weight: 400;
    margin: 0;
    min-height: var(--paragraph-line-height);
    padding: 0 0 0 32px;
    position: relative;
    text-transform: none;
    user-select: none;
    width: 100%;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .option-field__input-label-span {
    display: inline-block;
    vertical-align: baseline;
  }

  .option-field__icon {
    color: $color-blue;
    display: block;
    height: 24px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity $easing-outofview, background-color $easing-outofview, color $easing-outofview;
    width: 24px;
  }

  .option-field__icon-background {
    opacity: 1;
  }

  .option-field__icon-foreground {
    background-color: $color-blue;
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 18px;
  }

  &.option-field--required {
    .option-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.option-field--required) {
    .option-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.option-field--error {
    .option-field__error {
      @include typography-caption;

      align-items: center;
      color: $color-error;
      display: block;
      font-weight: 500;
      max-width: 100%;
      width: auto;

      &::before {
        background: svg-uri('<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#E9190A" cx="7.5" cy="7.5" r="7.5"/><path stroke="#FFF" d="M7.5 3v7M7.5 11v1"/></g></svg>');
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: 15px;
        margin-right: 5px;
        width: 15px;
      }
    }

    .option-field__input-label-span {
      color: $color-error;
    }

    .option-field__icon-background {
      color: $color-error;
    }
  }

  &.option-field--inline {
    .option-field__item {
      display: inline-block;
      margin-right: 20px;
      width: auto;
    }
  }

  &.option-field--radio {
    .option-field__icon-foreground {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.option-field--checkbox {
    //
  }
}

.option-field--checkbox {
  .option-field__icon-foreground {
    background-color: $color-blue;
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 18px;
  }
}

.option-field--radio {
  .option-field__icon {
    margin-top: 1px;
  }
}
