
.linklist {
  position: relative;

  .linklist__title {
    @include typography-h4;

    margin-top: 3px;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .linklist__items {
    //
  }

  .linklist__item {
    //
  }

  .linklist__anchor {
    @include typography-tag;

    color: $color-blue;
    text-decoration: none;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::after {
      content: '›';
      display: inline-block;
      font-size: 1.7em;
      margin: .15em 0 -2.5em .25em;
      vertical-align: top;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
