
.article-content {
  margin: 10px 0;
  position: relative;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper,
  .article-content__aside {
    grid-column: span var(--grid-columns);
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .article-content__title {
    @include typography-h2;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    text-transform: uppercase;
  }

  .article-content__quote {
    @include typography-h3;

    color: $color-blue;
  }

  @media (min-width: 960px) {
    .article-content__wrapper {
      grid-column: column 5 / span 7;
      order: 2;
    }

    .article-content__aside {
      grid-column: column 1 / span 4;
      order: 1;

      .article-content__title {
        transform: translateX(-2px);
      }

      .linklist .linklist__title {
        margin-top: 0;
      }
    }
  }
}

.article-content--default {
  //
}

.article-content--introduction {
  //
}

.article-content--quote {
  .article-content__quote {
    @include plus-container-blue-inset;

    margin: -20px -10px 0 -30px;
    padding: 20px 30px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::before {
      width: 100%;
    }
  }

  .article-content__aside {
    align-items: flex-start;
    display: flex;
  }

  @media (min-width: 960px) {
    .article-content__aside {
      margin-top: calc(var(--grid-spacing) + 8px);
    }

    .article-content__quote {
      margin: -20px -10px -20px -30px;
      padding: 20px 30px;

      &::before {
        width: calc(100% - 20px);
      }
    }
  }
}
