
.search-field {
  position: relative;
  width: 100%;

  .search-field__form {
    border-bottom: solid 2px $color-black;
    display: flex;
    justify-content: space-between;
  }

  .search-field__input {
    @include typography-h2;

    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    flex-grow: 1;
    outline: none;
    text-transform: uppercase;
    width: 80%;

    &::-webkit-input-placeholder {
      color: currentColor;
    }

    &:-ms-input-placeholder {
      color: currentColor;
    }

    &::placeholder {
      color: currentColor;
    }
  }

  .search-field__button {
    height: 40px;
    min-width: 0;
    width: 40px;
  }

  .search-field__button__search,
  .search-field__button__clear {
    display: none;
  }

  &.has-value {
    .search-field__button__search {
      display: none;
    }

    .search-field__button__clear {
      display: block;
    }
  }

  &.has-input {
    .search-field__button__clear {
      display: none;
    }

    .search-field__button__search {
      display: block;
    }
  }

  .search-field__results {
    @include typography-tag;

    margin-top: 10px;
    text-transform: uppercase;
  }

  @include landscape {
    .search-field__form {
      padding: 10px 0;
    }
  }
}
