
.related-people-grid {
  margin-bottom: 50px;
  margin-top: 60px;
  position: relative;

  .related-people-grid__grid {
    @include grid-container;

    position: relative;
  }

  .related-people-grid__title {
    @include typography-h2;

    grid-column: span var(--grid-columns);
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  .related-people-grid__aside {
    grid-column: span var(--grid-columns);
  }

  .related-people-grid__subtitle {
    @include typography-h3;

    color: $color-blue;
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  .related-people-grid__item {
    grid-column: span 3;
    margin-top: var(--grid-spacing);
    pointer-events: none;

    .thumbnail__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }

    .thumbnail__title {
      margin-bottom: 5px;
      order: 0;
    }

    .thumbnail__subtitle {
      margin-bottom: 0;
      order: 1;
    }
  }

  .thumbnail__title {
    @include typography-h4;

    text-transform: uppercase;
  }

  .thumbnail__subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include portrait {
    .related-people-grid__item {
      grid-column: span 2;
    }
  }

  @include landscape-only {
    .related-people-grid__aside {
      grid-column: span 4;

      + .related-people-grid__item {
        grid-column: column 1 / span 3;
      }
    }

    .related-people-grid__item {
      grid-column: span 3;
    }
  }

  @include notebook {
    .related-people-grid__aside {
      grid-column: span 4;
      order: unset;
    }

    .related-people-grid__item {
      grid-column: span 2;
    }
  }
}
