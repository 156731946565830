
.article-footer {
  margin: var(--margin) 0;
  position: relative;

  .article-footer__grid {
    @include grid-container;
  }

  .article-footer__wrapper {
    grid-column: span var(--grid-columns);
    margin-right: auto;
    max-width: 32em;
    padding-top: 40px;
  }

  .article-footer__share-title {
    @include typography-h4;

    margin-bottom: 1em;
    text-transform: uppercase;
  }

  .article-footer__share-buttons,
  .article-footer__buttons {
    margin-top: .5rem;
  }

  @include landscape {
    .article-footer__wrapper {
      grid-column: column -4 / span 4;
    }
  }
}
