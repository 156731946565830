
.number-button {
  @include typography-h4;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0; // iOS
  color: inherit;
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  padding: 7px 0;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: color $easing-hover, background-color $easing-hover, border-color $easing-hover;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 24px;

  .number-button__span {
    display: block;
    flex: 1 1 100%;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .number-button__icon {
    display: block;
    flex: 0 0 24px;
    height: 24px;
    width: 24px;

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}

a.number-button {
  color: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    .number-button__span {
      color: $color-blue;
      text-decoration: none;
    }
  }
}

.number-button--default {
  //
}

.number-button--clean {
  background-color: transparent;
  color: currentColor;
}

.number-button--disabled {
  opacity: .25;
  pointer-events: none;
}

.number-button--previous,
.number-button--next {
  background-color: $color-white;
  border: 1px solid transparent;
  border-color: $color-white;
  color: $color-black;
  opacity: .25;
  padding: 7px;
  pointer-events: none;
  width: 40px;
}

a.number-button--selected {
  .number-button__span {
    text-decoration: underline;
  }
}

a.number-button--previous,
a.number-button--next {
  background: $color-blue;
  border: 1px solid $color-blue;
  color: $color-white;
  opacity: 1;
  pointer-events: initial;

  &:hover,
  &:focus {
    background: $color-white;
    border-color: $color-blue;
    color: $color-blue;
  }

  &.number-button--disabled {
    background: $color-white;
    border-color: $color-white;
    color: $color-black;
  }
}
