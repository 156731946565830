
.project-header {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  margin-top: 150px;
  position: relative;

  .project-header__grid {
    @include grid-container;

    flex: auto;
  }

  .project-header__wrapper {
    grid-column: span var(--grid-columns);
    padding-bottom: var(--grid-spacing);
    padding-top: var(--grid-spacing);
    position: relative;
  }

  .project-header__category {
    @include typography-tag;

    color: $color-blue;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .project-header__title {
    @include typography-h1-title;

    color: $color-blue;
    margin-bottom: 1rem;
    margin-left: -.06em;
  }

  .project-header__title-span {
    text-transform: uppercase;
  }

  .project-header__title-count {
    @include typography-tag;

    display: inline-block;
    transform: translate3d(-1rem, -50%, 0);
    vertical-align: top;
  }

  .project-header__picture {
    flex: 0 1;
    flex-basis: 100%;
    overflow: hidden;

    .picture {
      height: 100%;
      margin-right: calc((var(--grid-padding) + var(--grid-margin)) * -1);
      width: calc(100% + var(--grid-padding) + var(--grid-margin));
    }
  }

  @include landscape {
    height: 53vw;
    margin-top: 0;
    max-height: 100vh;
    min-height: 700px;

    .project-header__wrapper {
      grid-column: column 5 / span 6;
      min-height: 200px;
      order: 2;
    }
  }

  @include notebook {
    .project-header__wrapper {
      grid-column: column 5 / span 7;
    }
  }
}
