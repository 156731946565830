// Base colors
$color-black: #000;
$color-white: #fff;

// Theme colors
$color-blue: #006bff;
$color-darkblue: #01253f; // TODO: Remove?

// Greyscale colors
$color-grey: #efefef;
$color-lightgrey: $color-grey; // TODO: remove, Compatibility
$color-mediumgrey: #979797;
$color-mediumdarkgrey: #666;
$color-darkgrey: #333;

// Functional colors
$color-error: #e9190a;

// Transparencies / overlays
$overlay-darkenphoto-flat: rgba($color-black, .15);
$overlay-darkenphoto-gradient: linear-gradient(to bottom, rgba($color-black, .15) 0%, rgba($color-black, .15) 50%, rgba($color-black, .5) 100%);
$overlay-whitebutton: rgba($color-white, .5);
$overlay-whitepanel: rgba($color-white, .75);
$overlay-menushadow: linear-gradient(to bottom, rgba($color-black, .15) 0%, rgba($color-black, 0) 100%);
$overlay-disabledbutton: rgb($color-white, .2);
$overlay-bluebutton: rgba($color-blue, .75);

// Beziers
//$bezier-fancy: cubic-bezier(.26, .88, .57, .9);
//$bezier-tighter: cubic-bezier(.23, 1, .32, 1);

// Easings
//$easing-change: .35s $bezier-fancy;
//$easing-change-slower: .65s $bezier-fancy;
//$easing-change-faster: .15s $bezier-fancy;
//$easing-change-flow: 1s $bezier-fancy;
//$easing-change-extraslow: 1.5s $bezier-fancy;
//$easing-linear: .35s linear;
$easing-breakpoint: .3s ease-out;
$easing-hover: .15s ease-out;
$easing-outofview: .1s ease-out;
$easing-intoview: .2s ease-out;
$easing-outofview-halfspeed: .2s ease-out;
$easing-intoview-halfspeed: .4s ease-out;
$easing-slower: .5s ease-out;

// Layers / z-indexes
$z-index-header: 2000;
$z-index-header-logo: 1000;
$z-index-header-menu: 1001;
$z-index-hit-target: 1000;
$z-index-scroll-down: 1000;
$z-index-menu-overlay: 2500;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  // Colors
  --color-black: #{$color-black};
  --color-white: #{$color-white};
  --color-blue: #{$color-blue};
  --color-darkblue: #{$color-darkblue};
  --color-grey: #{$color-grey};
  --color-lightgrey: #{$color-lightgrey};
  --color-mediumgrey: #{$color-mediumgrey};
  --color-darkgrey: #{$color-darkgrey};
  --color-error: #{$color-error};

  // Width-Height Utils
  //--actual-vw: calc(100vw - 2 * var(--grid-padding));
}
