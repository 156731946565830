
.people-grid {
  margin-bottom: 50px;
  margin-top: 60px;
  position: relative;

  .people-grid__grid {
    @include grid-container;

    grid-gap: var(--grid-padding);
    position: relative;
  }

  .thumbnail {
    @include clearfix;

    height: calc(100% + var(--grid-padding));
    margin: calc((var(--grid-padding) / 2) * -1);
    padding: var(--grid-gutter);

    &:hover {
      z-index: 10;
    }

    .thumbnail__content {
      display: flex;
      flex-direction: column;
    }

    .thumbnail__title {
      margin-bottom: 5px;
      order: 0;
    }

    .thumbnail__subtitle {
      margin-bottom: 0;
      order: 1;
    }
  }

  &.people-grid--no-plus {
    .thumbnail {
      &::before {
        display: none;
      }
    }
  }

  .people-grid__title {
    @include typography-h2;

    grid-column: span var(--grid-columns);
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  .people-grid__item {
    grid-column: span 3;
    position: relative;

    &:hover {
      z-index: 10;
    }

    .plus-corners {
      height: calc(100% + 20px);
      left: -10px;
      top: -10px;
      width: calc(100% + 20px);
    }
  }

  @include portrait {
    .people-grid__item {
      grid-column: span 2;
    }
  }

  @include landscape {
    .people-grid__item {
      grid-column: span 3;
    }
  }
}
