// Old HNS normalize

* {
  text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  @include fix-antialiasing;

  line-height: 1;
}

a,
abbr,
acronym,
address,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

ol {
  counter-reset: li;
  list-style: none;
  padding-left: 0;

  > li {
    &::before {
      content: counter(li);
      counter-increment: li;
      left: -1.25em;
      position: absolute;
      width: 1.25em;
    }
  }
}

ol > li,
ul > li {
  margin-left: 1.5em;
  position: relative;

  > ol,
  > ul {
    padding-left: 1.5em;
  }
}

ul {
  list-style: none;

  li {
    margin-left: 0;
  }
}

li > ol,
li > ul {
  padding-left: 1.5em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

caption,
td,
th {
  font-weight: normal;
  text-align: left;
}

caption,
th {
  vertical-align: middle;
}

td {
  vertical-align: top;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: '';
    content: none;
  }
}

iframe,
img,
video {
  vertical-align: bottom;

  &.full-size {
    height: auto;
    width: 100%;
  }
}

a {
  text-decoration: none;

  img {
    border: 0;
  }
}

article,
main,
.constrainer {
  @include clearfix;

  position: relative;
}

textarea {
  resize: vertical;
}

button,
input,
keygen,
select,
textarea {
  font: inherit;
}

label {
  display: block;
}

input[type='checkbox'],
input[type='radio'] {
  float: left;

  + label {
    display: block;
    padding-left: 2em;
  }

  label + & {
    clear: left;
  }
}

label[for],
input[type='submit'],
input[type='button'],
button,
select {
  cursor: pointer;

  *:hover,
  &:focus {
    outline: none;
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

sub,
sup {
  font-size: .5em;
  line-height: 1em;
  padding: 0 .25em;
}

sup {
  position: relative;
  top: -.75em;
}

small {
  font-size: 85%;
  line-height: 85%;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='email'],
input[type='url'],
input[type='date'],
input[type='tel'],
select,
textarea, {
  appearance: none;
  border-radius: 0;
}

input[type='search']::-webkit-search-decoration {
  appearance: none;
}

::-webkit-search-cancel-button {   // select all "X" buttons in search inputs
  appearance: none;      // hide the default "X" button
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: bold;
}

.hidden {
  display: none;
}

button {
  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
