
.homepage-tweener {
  position: relative;

  .homepage-tweener__slide {
    height: 100vh;
    position: relative;
  }

  .homepage-tweener__plus-visual {
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    //transition: .75s ease-out;
    width: 100px;
    z-index: 10;

    svg {
      height: 100%;
      width: 100%;

      path {
        fill: $color-blue;
      }
    }
  }

  .homepage-tweener__plus-visual--hidden {
    opacity: 0;
    visibility: hidden;
  }

  @include landscape {
    .homepage-tweener__plus-visual {
      height: 140px;
      width: 140px;
    }
  }

  /*
  height: var(--100vh, 100vh);
  min-height: 100dvh; // stylelint-disable unit-no-unknown
  //min-height: -webkit-fill-available; // iOS 15 address bar floaty thing hack
  opacity: 0;
  transition: opacity .35s ease-out;

  .homepage-tweener__inner {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .swiper {
    height: 100%;
    width: 100%;

    &.is-transitioning {
      .swiper-slide {
        pointer-events: none;
      }
    }
  }
  */

  /*
  .homepage-tweneer__pagination {
    color: $color-black;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: calc(var(--grid-gutter) + var(--grid-margin));
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $easing-hover;
    z-index: 100;

    html:not(.has-scrolled) & {
      opacity: 1;
    }
  }

  .homepage-tweener__pagination--white {
    color: $color-white;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid currentColor;
    display: block;
    height: 8px;
    transition: background-color $easing-hover, border-color $easing-hover, color $easing-hover;
    width: 8px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover,
    &:focus {
      background-color: currentColor;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: currentColor;
  }

  .swiper-pagination-current {
    background-color: currentColor;
  }

  &[data-initialized='true'] {
    opacity: 1;
  }
  */
}
