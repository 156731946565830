
.simple-grid {
  margin-bottom: 50px;
  margin-top: 60px;
  position: relative;

  .simple-grid__grid {
    @include grid-container;

    position: relative;
  }

  .simple-grid__title {
    @include typography-h2;

    grid-column: span var(--grid-columns);
    margin-bottom: 40px;
    text-transform: uppercase;
  }
}

.simple-grid--default {
  .simple-grid__item {
    grid-column: span var(--grid-columns);
  }

  @include landscape {
    .simple-grid__item {
      grid-column: span 3;
    }
  }
}

.simple-grid--small {
  .simple-grid__item {
    grid-column: span 3;
  }

  @include portrait {
    .simple-grid__item {
      grid-column: span 2;
    }
  }

  @include landscape {
    .simple-grid__item {
      grid-column: span 3;
    }
  }
}
