
.header {
  height: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-header;

  .header__grid {
    @include grid-container;
  }

  .header__wrapper {
    grid-column: column 1 / span var(--grid-columns);
    height: 0;
    overflow: visible;

    &::before {
      background-color: $color-white;
      content: '';
      display: block;
      height: 64px;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transform: translateY(-100%);
      transition: .5s ease-in-out;
      width: 100%;
      z-index: 0;
    }
  }

  .logo {
    float: left;
    height: auto;
    margin-top: var(--grid-spacing);
    width: 98px;
    //z-index: $z-index-header-logo;
  }

  .menu {
    float: right;
    //z-index: $z-index-header-menu;
  }

  &.header--sticky {
    position: fixed;

    &:not(.header--homepage) {
      .header__wrapper {
        &::before {
          pointer-events: initial;
          transform: translateY(0);
        }
      }
    }
  }

  @include landscape {
    .logo {
      width: 183px;
    }
  }
}

.header--white {
  .header__wrapper {
    &::before {
      background-color: $color-black;
    }
  }

  .logo {
    color: $color-white;

    &:hover {
      .logo__mini__plus path {
        fill: $color-blue;
      }
    }
  }

  .menu__buttons {
    color: $color-white;
  }
}
