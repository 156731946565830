
html,
body {
  min-width: $website-min-width;
}

body {
  position: relative;
  //
}

main {
  overflow: hidden;
}

html.prevent-scrolling {
  overflow: hidden;
}

body.body--specialisation {
  background-color: $color-lightgrey;
  color: $color-black;
}
