
.carousel {
  margin: 40px 0;
  position: relative;

  .carousel__wrapper {
    overflow: hidden;
    position: relative;
  }

  .swiper-container {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
      background: $color-white;
      border-width: 0;
      bottom: 0;
      color: $color-blue;
      height: 40px;
      line-height: 24px;
      margin-bottom: auto;
      margin-top: auto;
      min-width: 40px;
      padding: 8px;
      position: absolute;
      text-align: center;
      top: 0;
      transition: background-color $easing-hover, color $easing-hover;
      width: 40px;
      z-index: 100;

      .button__span {
        display: none;
      }

      &:focus {
        background: $color-white;
        color: $color-blue;
      }

      &:hover {
        background-color: $color-blue;
        color: $color-white;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  .swiper-slide {
    background-color: $color-white;

    .figure {
      .picture {
        height: 0;
        padding-bottom: 40%;
        width: 100%;

        .picture__image {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}

.carousel--small {
  .swiper-slide .figure .picture {
    padding-bottom: 70%;

    @include landscape {
      padding-bottom: 75%;
    }
  }

  .carousel__grid {
    @include grid-container;
  }

  .carousel__wrapper {
    grid-column: span var(--grid-columns);
  }

  @include landscape {
    .carousel__wrapper {
      grid-column: column 5 / span 7;
    }
  }
}

.carousel--full-width {
  .swiper-slide .figure .picture {
    padding-bottom: 60%;

    @include landscape {
      padding-bottom: 45%;
    }
  }

  .carousel__grid {
    @include grid-container;
  }

  .carousel__wrapper {
    grid-column: span var(--grid-columns);
  }

  @include landscape {
    .carousel__wrapper {
      grid-column: span var(--grid-columns);
    }
  }
}

.carousel--full-bleed {
  .swiper-slide .figure .picture {
    padding-bottom: 60%;

    @include landscape {
      padding-bottom: 45%;
    }
  }

  .figure__caption {
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }
}
