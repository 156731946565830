
.homepage-scroller-content {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  .homepage-scroller-content__grid {
    @include grid-container;

    display: block;
    width: 100%;
  }

  .homepage-scroller-content__inner {
    text-align: left;
  }

  .homepage-scroller-content__text-container {
    height: 50vh;

    &:nth-child(1) {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 70px;
    }

    &:nth-child(2) {
      padding-top: 70px;
    }
  }

  .homepage-scroller-content__text-container--introduction {
    .homepage-scroller-content__text {
      @include typography-intro;
    }
  }

  .homepage-scroller-content__text {
    @include typography-body;

    margin-bottom: 0;
  }

  .homepage-scroller-content__text-footer {
    margin-top: 20px;
  }

  .button--clean {
    color: $color-blue;
    padding-left: 0;

    &:hover,
    &:focus {
      color: $color-black;
    }
  }

  @include mobile-only {
    .button-group__list {
      width: 100%;
    }

    .button-group__item {
      margin-right: 0;
      text-align: center;
    }

    .button--secondary {
      width: 100%;
    }

    .homepage-scroller-content__text-container {
      .homepage-scroller-content__text {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  @include breakpoints(mobile-only, portrait-only) {
    .homepage-scroller-content__text-container {
      &:nth-child(1) {
        flex-direction: column;
      }
    }
  }

  @include landscape {
    .homepage-scroller-content__inner {
      column-count: 2;
      column-gap: 220px;
      display: block;
    }

    .homepage-scroller-content__text-container {
      height: auto;

      &:nth-child(1) {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}
