
.article-iframe {
  margin: 40px 0;
  position: relative;

  .article-iframe__grid {
    @include grid-container;
  }

  .article-iframe__wrapper {
    grid-column: span var(--grid-columns);
    position: relative;
  }

  @include landscape {
    .article-iframe__wrapper {
      grid-column: span var(--grid-columns);
    }
  }
}
